import React from 'react'

function Thanku() {
  return (
    <div className='w-sreen h-screen flex items-center justify-center text-xl text-gray-300 p-10'>
        <p className='text-center'>       Congrats you have been signed in successfully .        </p>
    </div>
  )
}

export default Thanku
